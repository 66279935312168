import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Link } from 'gatsby';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

import Toolbar from '@material-ui/core/Toolbar';
import logoSvg from '../images/tellspin-logo-white.svg';
import logoSvgBlue from '../images/tellspin-logo-blue.svg';
import SlackButton from './SlackButton';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  moreButton: {
    color: 'white',
  },
  moreButtonBlue: {
    color: theme.palette.primary.main,
  },
  logo: {
    maxHeight: 35,
    marginRight: theme.spacing(1),
  },
  title: {
    color: 'white',
    paddingRight: theme.spacing(5),
    overflow: 'visible',
  },
  titleBlue: {
    paddingRight: theme.spacing(5),
    color: theme.palette.primary.main,
    overflow: 'visible',
  },
  whiteNavButton: {
    textTransform: 'capitalize',
    marginLeft: 8,
    fontSize: '1rem',
    color: 'white',
  },
  blueNavButton: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    marginLeft: 8,
    fontSize: '1rem',
  },
  right: {
    display: 'flex',
  },
  slackButton: {
    height: 'auto',
    width: 130,
  },
}));

function ElevationScroll(props) {
  const theme = useTheme();

  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: trigger
      ? {
          backgroundColor: 'white',
          color: theme.palette.primary.main,
          transition: 'background-color 0.5s ease',
        }
      : {
          transition: 'background-color 0.5s ease',
        },
  });
}

export default function Navbar(props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  let handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <ElevationScroll {...props}>
      <AppBar>
        <Toolbar>
          <Link to="/" className={classes.link}>
            <img
              alt="tellspin logo"
              className={classes.logo}
              src={trigger ? logoSvgBlue : logoSvg}
            />
            <Typography
              className={trigger ? classes.titleBlue : classes.title}
              variant="h5"
              component="div"
            >
              DOaThing
            </Typography>
          </Link>
          <div style={{ flex: 1 }} />
          <Hidden xsDown implementation="css">
            <div className={classes.right}>
              <Button
                className={
                  trigger ? classes.blueNavButton : classes.whiteNavButton
                }
                component={Link}
                to="/features/"
                color="primary"
              >
                Features
              </Button>
              <Button
                className={
                  trigger ? classes.blueNavButton : classes.whiteNavButton
                }
                component={Link}
                to="/pricing/"
                color="primary"
              >
                Pricing
              </Button>
              <Button
                className={
                  trigger ? classes.blueNavButton : classes.whiteNavButton
                }
                component={Link}
                to="/about/"
                color="primary"
              >
                About
              </Button>
              {/*
              <Button
                className={
                  trigger ? classes.blueNavButton : classes.whiteNavButton
                }
                component={Link}
                to="/get-started/"
                color="primary"
              >
                Get Started
              </Button>
              */}
              <div className={classes.blueNavButton}>
                <SlackButton
                  funnel={`${props.funnelString}-navbar`}
                  className={classes.slackButton}
                />
              </div>
            </div>
          </Hidden>
          <Hidden smUp>
            <IconButton
              className={trigger ? classes.moreButtonBlue : classes.moreButton}
              aria-label="More"
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/features/" onClick={handleClose}>
                Features
              </MenuItem>
              <MenuItem component={Link} to="/pricing/" onClick={handleClose}>
                Pricing
              </MenuItem>
              <MenuItem component={Link} to="/about/" onClick={handleClose}>
                About
              </MenuItem>
              <MenuItem component={Link} to="/blog/" onClick={handleClose}>
                Blog
              </MenuItem>
              {/*
              <MenuItem
                component={Link}
                to="/get-started/"
                onClick={handleClose}
              >
                Get Started
              </MenuItem>
              */}
              <MenuItem>
                <SlackButton funnel={`${props.funnelString}-navbar-mobile`} />
              </MenuItem>
            </Menu>
          </Hidden>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
