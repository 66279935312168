import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    marginRight: 10,
    color: 'white',
    textDecoration: 'none',
  },
  links: {
    display: 'grid',
    gridTemplateColumns: '100%',
    alignContent: 'space-evenly',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '50% 50%',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '15% 15% 15% 15% 15% 15%',
    },

    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  linkText: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

const FootLink = ({ to, text }) => {
  const classes = useStyles();
  return (
    <Typography variant="body1" className={classes.linkText}>
      <Link className={classes.link} to={to}>
        {text}
      </Link>
    </Typography>
  );
};

const Footer = ({ siteTitle }) => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography variant="h6" component="div" align="center" gutterBottom>
          <a className={classes.link} href="https://plainice.com">
            <strong>PlainIce</strong>
          </a>
        </Typography>
        <Typography variant="subtitle1" align="center" component="p">
          Cool refreshing software
        </Typography>
        <div className={classes.links}>
          <FootLink to="/contact-us/" text="Contact Us" />
          <FootLink to="/privacy/" text="Privacy Policy" />
          <FootLink to="/terms-of-use/" text="Terms of Use" />
          <FootLink to="/pricing/" text="Pricing" />
          <FootLink to="/blog/" text="Blog" />
          <FootLink to="/about/" text="About" />
        </div>
        <Typography variant="body2" align="center">
          {'Copyright © '}
          <a className={classes.link} href="https://plainice.com">
            PlainIce
          </a>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
